import { Link, graphql, useStaticQuery } from 'gatsby'

import Columns from 'react-columns'
import Head from '../components/head'
import Hero from '../components/hero'
import HeroImage from '../assets/PythonCoding.jpg'
import Layout from '../components/layout'
import React from 'react'
import blogStyle from '../styles/blog.module.scss'

const ContentfulBlog = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            author
            category
            categorySlug
            thumbnail{
              file{
                url
              }
            }
            publishedDate(fromNow: true)
            excerpt{
              childMarkdownRemark{
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  `)
  
  var queries = [{
    columns: 2,
    query: 'min-width: 1100px'
  }];
  
    return (
      <Layout>
        <Head title="Blog" />
        <Hero image={HeroImage} text="Learn to Code: Python Programming"/>
        <div className={blogStyle.divider}>
          <div className={blogStyle.posts}>
          <Columns queries={queries}>
            {data.allContentfulBlogPost.edges.map((edge) => {
              return (
                  <div className={blogStyle.postsContent}>
                  <Link to={`${edge.node.categorySlug}/${edge.node.slug}`}>
                    <img className={blogStyle.image} alt="YouTube Thumbnail" src={edge.node.thumbnail.file.url}></img>
                  </Link>
                  <Link className={blogStyle.slug} to={`${edge.node.categorySlug}/${edge.node.slug}`}>
                    {edge.node.title}
                  </Link>
                  <div className={blogStyle.bubbleHolder}>
                    <p className={blogStyle.bubbles}>{edge.node.publishedDate}</p>
                    <p className={blogStyle.bubbles}>{edge.node.author}</p>
                    <p className={blogStyle.bubbles}>{edge.node.category}</p>
                  </div>
                  <p className={blogStyle.excerpt}>{edge.node.excerpt.childMarkdownRemark.rawMarkdownBody}</p>
                </div>                
              )
            })}
            </Columns>
          </div>
        </div>
      </Layout>
    )
}

export default ContentfulBlog